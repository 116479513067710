import React from "react";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#FFBC51",
      light: "#FFBC51",
      dark: "#FFBC51",
    },
    accent: {
      main: "#2C3A73"
    },
    background: {
      default: "#1D294D",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === "true" ? "#1D294D" : "#4d1d23",
      }
    },
    MuiTypography: {
      h1: {
        fontSize: "2.25rem",
      },
      h2: {
        fontSize: "1.125rem"
      },
      body1: {
        fontSize: "1rem",
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8rem"
      }
    },
    MuiBackdrop: {
      root: {
        backdropFilter: "blur(1.2px)"
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#1D294D"
      }
    },
    MuiButton: {
      outlinedSecondary: {
        borderColor: "#102645",
        color: "#102645"
      },
    },
    MuiDrawer: {
      paper: {
        background: "#1D294D",
      },
    },
  },
});

function withTheme(Component) {
  function withProps(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return withProps;
}

export default withTheme;