import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import Content from './Components/Content';
import Bar from './Components/Bar';
import withTheme from './withTheme';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_TACKING_CODE
};

const styles = (theme) => ({
  mainWrapper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  backgroundImage: {

    backgroundImage: 'url(./insight-logo.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.2,
    zIndex: -1,
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    if (!app.apps.length) {
      this.fbApp = app.initializeApp(firebaseConfig);
    }

    this.state = {
      user: null,
      ocr: null,
      existingAdmins: [],
    }

    this.getOcrCsv = this.getOcrCsv.bind(this);
    this.onUploadNewFile = this.onUploadNewFile.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
    this.handleRemoveExistingAdmin = this.handleRemoveExistingAdmin.bind(this);
  }

  componentDidMount() {
    // if running on dev, auth won't work
    if (process.env.NODE_ENV === 'development') {
      this.setState({ user: { displayName: "Din Djarin", isAdmin: true } });
      this.getOcrCsv();
      return;
    }

    let db = this.fbApp.firestore()

    this.removeAuthObserver = this.fbApp.auth().onAuthStateChanged((user) => {
      if (user) {
        // get user data from firestore and combine with firebase auth
        this.unsubUser = db.collection('Users').doc(user.uid).onSnapshot(userDoc => {
          let combinedUserData = { ...user, ...userDoc.data() };
          this.setState({ user: combinedUserData });
          if (!this.state.ocr) {
            this.getOcrCsv();
          }
        });
        // maintain list of admins
        this.unsubAdmins = db.collection('Users').where('isAdmin', '==', true).onSnapshot(adminsSnapshot => {
          this.setState({ existingAdmins: adminsSnapshot.docs });
        });
      } else {
        // no user
        this.setState({ user: null });

        // check if there's url params - if there is then we've come through a redirect
        var code = this.getURLParameter('code');
        var state = this.getURLParameter('state');
        var error = this.getURLParameter('error');

        if (error) {
          console.error(error);
        } else if (code) {

          var tokenFunctionURL = 'https://us-central1-' + process.env.REACT_APP_PROJECT_ID + '.cloudfunctions.net/token';
          var reqLink = tokenFunctionURL +
            '?code=' + encodeURIComponent(code) +
            '&state=' + encodeURIComponent(state);

          fetch(reqLink, { mode: 'cors' })
            .then((result) => {
              result.json().then((data) => {
                if (data.token) {
                  // sign in then remove the messy params from the url
                  this.fbApp.auth().signInWithCustomToken(data.token).then(() => window.history.replaceState({}, "", "/"));
                } else {
                  console.error(data);
                }
              });
            });
        } else {
          // if no url params, then we've hit the site fresh - go get that auth
          var authURL = 'https://us-central1-' + process.env.REACT_APP_PROJECT_ID + '.cloudfunctions.net/redirect';
          window.location.href = authURL;
        }
      }
    });
  }

  getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) ||
      [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

  getOcrCsv() {
    this.fbApp.storage().ref('ObligationRegister.csv').getDownloadURL().then((url) => {
      Papa.parse(url, {
        header: true,
        download: true,
        complete: results => {
          // last row or two seems to be empty
          // keep removing rows from the end until it isn't empty
          let resultData = results.data;
          let rowBackIsEmpty = true;
          while (rowBackIsEmpty) {
            if (!resultData[resultData.length - 1].Reference || resultData[resultData.length - 1].Reference.length < 2) {
              resultData.pop();
            } else {
              rowBackIsEmpty = false;
            }
          }

          this.setState({ ocr: resultData });
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  handleSignOut() {
    this.fbApp && this.fbApp.auth().signOut();
  }

  openDrawer = () => {
    this.setState({ drawerOpen: true });
  }

  closeDrawer = () => {
    this.setState({ drawerOpen: false });
  }

  componentWillUnmount() {
    this.removeAuthObserver && this.removeAuthObserver();
    this.unsubUser && this.unsubUser();
    this.unsubAdmins && this.unsubAdmins();
  }

  onUploadNewFile(file) {
    console.log("uploading", file.name);
    // Create the file metadata
    var metadata = { contentType: 'text/csv' };

    let storage = this.fbApp.storage();

    // Upload file and metadata to the object 'images/mountains.jpg'
    storage.ref().child('ObligationRegister.csv').put(file, metadata).then((snapshot) => {
      console.log("uploaded", snapshot);
    });
  }

  onSubmitEmail(email) {
    this.fbApp.firestore().collection('Users').doc(email).set({ isAdmin: true }, { merge: true });
  }

  handleRemoveExistingAdmin(adminID) {
    this.fbApp.firestore().collection('Users').doc(adminID).set({ isAdmin: false }, { merge: true });
  }

  render() {
    const { user, ocr, existingAdmins } = this.state;
    const { classes } = this.props;

    if (!user) {
      return (
        <Box m={4} style={{ textAlign: 'center' }}>
          <Typography>Authenticating</Typography>
          <LinearProgress style={{ marginTop: "20px" }} />
        </Box>
      );
    }

    // while waiting for papa to parse
    if (!ocr) {
      return (
        <Box m={4} textAlign={'center'}>
          <Typography>Loading database...</Typography>
          <LinearProgress style={{ marginTop: "20px" }} />
        </Box>
      );
    }

    // router is for url search params
    return (
      <Router>
        <div style={{ minHeight: '100vh' }}>
          <Container maxWidth="xl" className={classes.mainWrapper}>
            <Bar
              user={user}
              onSignOut={this.handleSignOut}
              onUploadNewFile={this.onUploadNewFile}
              onSubmitEmail={this.onSubmitEmail}
              existingAdmins={existingAdmins}
              handleRemoveExistingAdmin={this.handleRemoveExistingAdmin}
            />
            <Switch>
              <Route path="/">
                <Content ocr={ocr} />
                <div className={classes.backgroundImage} />
              </Route>
            </Switch>
          </Container>
        </div>
      </Router>
    );
  }
}

export default withTheme(withStyles(styles)(App));
