import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container as RGSContainer, Row as RGSRow, Col as RGSCol } from 'react-grid-system';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  mainBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.down('md')]: {
      //We've removed most page margins but need to push left side out as grid has right margins
      //and will look un-even otherwise
      marginLeft: "15px"
    }
  },
  linksSection: {
    width: "100%",
    backgroundColor: "white",
    color: "#414141",
    minHeight: "20rem",
  },
  linksGrid: {
    fontSize: "1rem"
  },
  sectionHeader: {
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  linkIcon: {
    paddingRight: "0.5rem",
    fontSize: "80%",
    color: "#016CA6"
  },
  link: {
    color: "#016CA6",
    textDecoration: "none",
    cursor: "pointer"
  },
  links: {
    paddingLeft: "0px"
  },
  button: {
    margin: theme.spacing(1),
  },
  addButtonIcon: {
    marginLeft: theme.spacing(1),
    color: "#102645"
  },
  fab: {
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    boxShadow: "0px 0px 0px 1px #102645",
    padding: "2px",
    marginLeft: "20px",
  },
  littleIcon: {
    fontSize: "14px",
    color: "#102645"
  }
});

class LinksSection extends Component {
  render() {
    const { title, classes, visible, links } = this.props;
    if (!visible) {
      return (<div ref={this.props.innerRef}></div>);
    }

    return (
      <div className={classes.linksSection} ref={this.props.innerRef}>
        <Box className={classes.mainBox}>
          <Box style={{ marginLeft: "-15px" }} className={classes.linksGrid}>
            <RGSContainer fluid style={{ lineHeight: '32px' }}>
              <RGSRow>
                <RGSCol>
                  <Typography variant="h4" className={classes.sectionHeader} noWrap>{title}</Typography>
                </RGSCol>
              </RGSRow>
              <RGSRow>
                {links.map(link => (
                  <RGSCol xs={8} sm={8} md={4} lg={4} xl={4} style={{ paddingBottom: "5px" }} key={link.name}>
                    <Box>
                      <span className={classes.linkIcon}>&gt;</span>
                      <span
                        className={classes.link}
                        onClick={(event) => this.props.handleLinkClick(link.name)}>
                        {`${link.name} (${link.count})`}
                      </span>
                    </Box>

                  </RGSCol>
                ))}
              </RGSRow>
            </RGSContainer>
          </Box>
        </Box>
      </div>
    );
  }
}

const LinksComponent = React.forwardRef((props, ref) => <LinksSection innerRef={ref} {...props} />);
//const StyledComponent = withStyles({styles})(LinksComponent);
const StyledComponent = withStyles(styles, { name: 'LinksSection' })(LinksComponent);
export default StyledComponent;