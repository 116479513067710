import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  card: {
    backgroundColor: "#fff",
    color: "#000"
  }
});

class ResultItem extends Component {
  render() {
    const { item, classes } = this.props;

    return (
      <Box my={4}>
        <Paper elevation={4} className={classes.card}>
          <Box px={3} py={2}>
            <Typography variant="h5">{item.Reference}</Typography>
            <Typography variant="h6">{item.Summary}</Typography>
            <Typography variant="subtitle2">{item.Source}</Typography>

            <Box m={1} fontWeight="fontWeightBold">
              Description
              <Typography style={{ whiteSpace: 'pre-line' }}>{item.Description}</Typography>
            </Box>
            <Box m={1} fontWeight="fontWeightBold">
              Definitions
              <Typography style={{ whiteSpace: 'pre-line' }}>{item.Definitions}</Typography>
            </Box>
            <Box m={1} fontWeight="fontWeightBold">
              Impact
              <Typography>{item.Impact}</Typography>
            </Box>
            <Box m={1} fontWeight="fontWeightBold">
              Relevant regulators
              <Typography>{item["Relevant regulators"]}</Typography>
            </Box>

            <Box textAlign="right">
              <Typography variant="overline">Categories: {item["Obligation category"]}</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ResultItem);
