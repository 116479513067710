import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import SVGIconLoader from './SVGIconLoader';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const styles = (theme) => ({
  tileWithBorder: {
    borderRightWidth: "5px",
    borderRightStyle: "solid",
    borderRightColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.accent.main, // remove this to show colours on pi portal
  },
  tileNoBorder: {
  },
  cardAction: {
    minHeight: "132px"
  },
  constrainedHeightTile: {
    maxHeight: "132px"
  },
  tileSingle: {
    minWidth: "132px",
    maxWidth: "150px"
  },
  tileDouble: {
    minWidth: "14rem",
    maxWidth: "20rem",
  },
  tileText: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
  iconsRight: {
    float: "right",
    lineHeight: "20px"
  }
});

class Tile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textOverflowed: false
    }
  }

  handleReflow = (rleState) => {
    const { clamped } = rleState
    //prevent infinite loop of reflow and only set state if different
    //the reponsive ellipsis tells us if its clamping (...) the text or not
    //which we align with our state to influence tooltips
    if (this.state.textOverflowed !== clamped) {
      this.setState({
        textOverflowed: clamped
      });
    }
  }

  getUrlFromIconFileName(fileName) {
    return "/icons/" + fileName;
  }

  render() {
    const { classes, showRightBorder, title } = this.props;

    let tileIconURL = "defaultIcon.svg";
    if (this.props.icon && this.props.icon !== "") {
      tileIconURL = this.props.icon;
    }
    let tileColour = "#2C3A73";
    if (this.props.colour) {
      tileColour = this.props.colour;
    } else if (this.props.sectionColour) {
      tileColour = this.props.sectionColour;
    }

    const borderClass = showRightBorder ? classes.tileWithBorder : classes.tileNoBorder;
    const cardClass = this.props.type === "singleTile" ? classes.tileSingle : classes.tileDouble;

    return (
      <Card onClick={() => this.props.handleClick()} className={`${cardClass} ${classes.constrainedHeightTile} ${borderClass}`} style={{ backgroundColor: tileColour }}>
        <CardActionArea component="div" disableRipple className={classes.cardAction}>
          <CardContent>
            <div>
              {tileIconURL !== "" &&
                <SVGIconLoader
                  iconURL={this.getUrlFromIconFileName(tileIconURL)}
                  title={title} />
              }
              {/*Tooltip will only show if the responsive ellipsis overflows to ... */}
              <Tooltip title={this.state.textOverflowed ? title : ""}>
                <ResponsiveEllipsis
                  className={classes.tileText}
                  text={title}
                  maxLine='2'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                  onReflow={this.handleReflow}
                />
              </Tooltip>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

Tile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Tile);
