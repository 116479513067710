import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ResultItem from './ResultItem';
import { Container } from '@material-ui/core';
import Fuse from 'fuse.js';
import { withStyles } from '@material-ui/core/styles';
import { getUrlParamObject, getUrlParamNameAndValue } from '../utils';
import { withRouter } from "react-router";
import { Container as RGSContainer, Row as RGSRow, Col as RGSCol } from 'react-grid-system';
import Tile from '../Components/Tile'
import LinkSection from './LinksSection';
import withTracker from '../withTracker';
import md5 from 'md5'
import Pagination from '@material-ui/lab/Pagination';

const styles = (theme) => ({
  mainBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.down('md')]: {
      //We've removed most page margins but need to push left side out as grid has right margins
      //and will look un-even otherwise
      marginLeft: "15px"
    },
    marginTop: theme.spacing(4)
  },
});

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredOcr: null,
      tileOpen: null,
      page: 1
    }

    this.updateFilteredOcr = this.updateFilteredOcr.bind(this);
    this.handleTileClick = this.handleTileClick.bind(this);
    this.getLinksFromSelectedTile = this.getLinksFromSelectedTile.bind(this);
    this.putCategoryInUrl = this.putCategoryInUrl.bind(this);
  }

  componentDidMount() {
    const urlParams = getUrlParamObject(this.props.location.search);
    if(urlParams.page && urlParams.page !== "" && !Number.isNaN(urlParams.page)){
      this.setState({ page: parseInt(urlParams.page)});
    }
    this.updateFilteredOcr();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevParams = getUrlParamNameAndValue(getUrlParamObject(prevProps.location.search)) || "";
    const currentParams = getUrlParamNameAndValue(getUrlParamObject(this.props.location.search)) || "";

    if (prevParams.value !== currentParams.value) {
      this.updateFilteredOcr();
    }

    //think we should do this better, rather than detecting here push another prop
    const paramObject = getUrlParamObject(this.props.location.search);
    if(!paramObject.page && this.state.page !== 1){
      //url has changed, reset back to 1st page
      this.setState({ page: 1 });
    }
  }

  // create a filtered ocr list from the category in url param
  updateFilteredOcr() {
    const filterValue = getUrlParamNameAndValue(getUrlParamObject(this.props.location.search));
    let resultSet = [];

    if (filterValue) {
      this.props.ocr.forEach(row => {
        if (row[filterValue.id].includes(filterValue.value)) {
          resultSet.push(row);
        }
      });
    } else {
      // no category selected, don't pass a result list
      resultSet = []
    }

    this.setState({ filteredOcr: resultSet });
  }

  handleTileClick(tile) {
    const currentTileOpen = this.state.tileOpen;
    let newTile = null;
    if (currentTileOpen !== tile) {
      newTile = tile;
    }

    this.setState({ tileOpen: newTile });
  }

  handlePageChange(event,page){
    const paramObject = getUrlParamObject(this.props.location.search);
    paramObject.page = page;

    let urlString = '/?';
    let index = 0;
    debugger;

    for (const [key, value] of Object.entries(paramObject)) {
      if(index > 0){
        urlString+= ',';
      }
      urlString += `${encodeURI(key)}=${encodeURI(value)}`;
      index++;
    }

    window['scrollTo']({top: 0, behavior: `auto`});

    this.props.history.push(urlString);
    this.setState({ page: page });
  }

  getLinksFromSelectedTile() {
    let links = [];

    var tileOpen = this.state.tileOpen;

    if (tileOpen) {
      links = this.getUniqueColumnValues(this.props.ocr, tileOpen);
    }

    return links;
  }

  getUniqueColumnValues(data, columnName) {
    let values = [];
    data.forEach(row => {
      const rowValues = row[columnName].split(';');
      rowValues.forEach(rowValue => {
        const rowValueName = rowValue.trim();
        if (rowValueName === "") {
          return;
        }

        const valueIndex = values.findIndex(value => value.name === rowValueName);
        if (valueIndex === -1) {
          values.push({ name: rowValueName, count: 1 });
        } else {
          values[valueIndex].count++;
        }
      });
    });

    return values.sort(this.compareValues);
  }

  compareValues(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  putCategoryInUrl(value) {
    const paramObject = getUrlParamObject(this.props.location.search);

    let urlString = `/?${encodeURI(this.state.tileOpen)}=${encodeURI(value)}`;

    if (paramObject.search) {
      urlString += ',search=';
      urlString += `${encodeURI(paramObject.search)}`;
    }
    window['scrollTo']({top: 0, behavior: `auto`});
    this.props.history.push(urlString);
  }

  render() {
    const { filteredOcr, tileOpen } = this.state;
    const { classes } = this.props;

    // waiting for filtering
    if (!filteredOcr) {
      return (
        <Box></Box>
      );
    }

    const filterValue = getUrlParamNameAndValue(getUrlParamObject(this.props.location.search));
    const searchText = getUrlParamObject(this.props.location.search).search || "";

    let resultSet = [];
    if (!searchText) {
      if (filterValue) {
        // show all in the category
        resultSet = filteredOcr;
      } else {
        // show screen with hints for searching
        return (
          <React.Fragment>
            <Box className={classes.mainBox} ref="mainBox">
              <Box mt={4} style={{ marginLeft: "-15px" }}>
                <RGSContainer fluid style={{ lineHeight: '32px' }}>
                  <RGSRow align="end">
                    <RGSCol xs={6} sm={6} md={3} style={{ paddingBottom: "30px" }}>
                      <Tile
                        title="Obligations"
                        icon="ic-obligations.svg"
                        showRightBorder={tileOpen === 'Obligation category'}
                        handleClick={() => this.handleTileClick('Obligation category')}
                      />
                    </RGSCol>
                    <RGSCol xs={6} sm={6} md={3} style={{ paddingBottom: "30px" }}>
                      <Tile
                        title="Relevant Regulators"
                        icon="ic-legislation.svg"
                        showRightBorder={tileOpen === 'Relevant regulators'}
                        handleClick={() => this.handleTileClick('Relevant regulators')}
                      />
                    </RGSCol>
                    <RGSCol xs={6} sm={6} md={3} style={{ paddingBottom: "30px" }}>
                      <Tile
                        title="Legislation"
                        icon="ic-regulations.svg"
                        showRightBorder={tileOpen === 'Act'}
                        handleClick={() => this.handleTileClick('Act')}
                      />
                    </RGSCol>
                    <RGSCol xs={6} sm={6} md={3} style={{ paddingBottom: "30px" }}>
                      <Tile
                        title="Business Unit"
                        icon="ic-business-unit.svg"
                        showRightBorder={tileOpen === 'Relevant business unit'}
                        handleClick={() => this.handleTileClick('Relevant business unit')}
                      />
                    </RGSCol>
                  </RGSRow>
                </RGSContainer>
              </Box>
            </Box>
            <LinkSection
              visible={tileOpen}
              title={tileOpen}
              links={this.getLinksFromSelectedTile()}
              handleLinkClick={(value) => this.putCategoryInUrl(value)} />
          </React.Fragment>
        );
      }
    } else {
      // search over filter if its filtered (we are in category), else over the whole dataset
      const ocrData = filteredOcr && filteredOcr.length > 0? filteredOcr : this.props.ocr;
      const minCharMatchLength = searchText.length > 3 ? 3 : searchText.length;
      let fuse = new Fuse(ocrData, {
        shouldSort: true,
        findAllMatches: true,
        includeMatches: false,
        minMatchCharLength: minCharMatchLength,
        threshold: 0.2,
        distance: 5000,
        //can enable below in debug but UI wont render as changes result structure
        //includeScore: true,
        //includeMatches: true,
        keys: [
          "Reference",
          "Summary",
          "Source",
          "Description",
          "Definitions",
          "Impact",
          "Relevant regulators",
          "Obligation category"
        ],
      });
      resultSet = fuse.search(searchText);
    }


    const pageSize = 50;
    const originalResultLength = resultSet.length;
    const pages = Math.ceil(resultSet.length / pageSize);
    const page = this.state.page;
    var resultsText = <Typography>Found {originalResultLength} results</Typography>;

    if (resultSet.length > pageSize) {
      
      const startIndex = (page - 1) * pageSize;
      const endIndex = resultSet.length > startIndex + pageSize ? startIndex + pageSize : resultSet.length;
      
      console.log("Page " + page + " startIndex: " + startIndex + " endIndex: " + endIndex + " pages:" + pages);
      resultSet = resultSet.slice(startIndex, endIndex);
  
      if(pages > 1){
        resultsText = <Typography>Found {originalResultLength} results (showing {startIndex + 1} to {endIndex } )</Typography>
      }

    }

    return (
      <Container maxWidth="lg">
        <Box mt={4}>
          {filterValue ?
            <Typography>Selected: {filterValue.name} - {filterValue.value}</Typography>
            :
            <Typography>No category selected</Typography>
          }
          {resultsText}
        </Box>
        {resultSet.map(r =>
          //key should just be reference but data has duplicates on that so hash of ref and summary
          //md5 hash since it can be long key
          <ResultItem
            key={md5(r.Reference + r.Summary)}
            item={r}
          />
        )}
        <Pagination
              count={pages}
              page={page}
              disabled={pages < 2}
              onChange={this.handlePageChange.bind(this)}
            />
      </Container>
    );
  }
}

Content = withTracker(Content);
export default withRouter(withStyles(styles)(Content));
