import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { getUrlParamObject, getUrlParamNameAndValue } from '../utils';
import { withRouter } from "react-router";
import SupportIcon from '@material-ui/icons/ContactSupport';
import SynergyLogo from '../assets/synergy_logo_white_text.png';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AdminDialog from './AdminDialog';

const styles = (theme) => ({
  appBar: {
    boxShadow: "none",
  },
  titleLink: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.accent.main,
  },
  menuLogo: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0.5)
  },
  supportButton: {
    marginLeft: "auto"
  },
  supportButtonIcon: {
    marginLeft: theme.spacing(1)
  },

  barBottomLine: {
    height: "1px",
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    width: "100%",
  },
  filterBox: {
    backgroundColor: "#102645",
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  textField: {
    '& .MuiInput-root': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '& .Mui-focused': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '&:hover': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'secondary',
    },
    '& .MuiFormLabel-root': {
      color: "#1D294D",
    },
  },
  textInput: {
    backgroundColor: "#fff",
    color: "#000",
  },
  clearButton: {
    color: "#1D294D",
  }
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: getUrlParamObject(props.location.search).search || "",
      authMenu: {
        anchorEl: null
      },
      adminOpen: false,
      selectedFile: null,
      enteredEmail: "",
    }

    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.putSearchInUrl = this.putSearchInUrl.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onEditEmail = this.onEditEmail.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
  }

  openAuthMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState({ authMenu: { anchorEl } });
  };

  closeAuthMenu = () => {
    this.setState({ authMenu: { anchorEl: null } });
  };

  openAdmin = () => {
    this.setState({ adminOpen: true, authMenu: { anchorEl: null } });
  };

  closeAdmin = () => {
    this.setState({ adminOpen: false, selectedFile: null });
  };

  onSelectFile(file) {
    this.setState({ selectedFile: file });
  }

  onClickUpload = () => {
    this.props.onUploadNewFile(this.state.selectedFile);
    this.closeAdmin();
  }

  onClickClear = () => {
    this.setState({ selectedFile: null });
  }

  onEditEmail(text) {
    this.setState({ enteredEmail: text });
  }

  onSubmitEmail = () => {
    this.props.onSubmitEmail(this.state.enteredEmail);
    this.onEditEmail("");
  }

  validateEmail(email) {
    return email.includes('@synergy.net.au');
  }

  handleChangeSearchText(text) {
    if (text.length > 30) {
      return;
    }
    this.setState({ searchText: text });
  }

  handleSearchKeyPress(e) {
    if (e.keyCode === 13) {
      const searchText = getUrlParamObject(this.props.location.search).search || "";
      if (searchText !== this.state.searchText) {
        this.putSearchInUrl(this.state.searchText);
      }
    }
  }

  handleClearSearch() {
    this.handleChangeSearchText("");

    const searchText = getUrlParamObject(this.props.location.search).search || "";
    if (searchText !== "") {
      this.putSearchInUrl("");
    }
  }

  putSearchInUrl(text) {
    const paramObject = getUrlParamObject(this.props.location.search);
    const filterValue = getUrlParamNameAndValue(paramObject);

    // don't touch the category bit
    let urlString = '/';
    if (filterValue) {
      urlString += `?${filterValue.id}=${encodeURI(filterValue.value)}`;
    }
    if (filterValue && text) {
      urlString += ',search=';
    }
    if (text) {
      if (!filterValue) {
        urlString += '?search=';
      }
      urlString += `${encodeURI(text)}`;
    }
    this.props.history.push(urlString);
  }

  handleSupportClicked = () => {
    // this link can pre-fill form variables
    const link = "https://synergyprod.service-now.com/csm?id=sc_cat_item&sys_id=a32501ee4face6009ec1d0311310c7fc&comments=Please%20type%20your%20feedback%20on%20a%20new%20line%20here:%20&short_description=Feedback%20for%20React%20OCR%20application%20(ASSIGN%20TO%20CORE%20SYSTEMS)";
    window.open(link);
  }

  render() {
    const { searchText, authMenu, adminOpen, selectedFile, enteredEmail } = this.state;
    const { user, classes } = this.props;

    const userNameArray = user && user.displayName ? user.displayName.split(" ", 2).map(name => name.charAt(0)) : ["N", "A"];

    return (
      <React.Fragment>
        <AdminDialog
          isOpen={adminOpen}
          onClose={this.closeAdmin}
          onSelectFile={this.onSelectFile}
          selectedFile={selectedFile}
          onClickUpload={this.onClickUpload}
          onClickClear={this.onClickClear}
          enteredEmail={enteredEmail}
          onEditEmail={this.onEditEmail}
          onSubmitEmail={this.onSubmitEmail}
          emailIsValid={this.validateEmail(enteredEmail)}
          existingAdmins={this.props.existingAdmins}
          handleRemoveExistingAdmin={this.props.handleRemoveExistingAdmin}
        />

        <AppBar
          color="inherit"
          position="static"
          className={classes.appBar}>
          <Toolbar variant="regular">

            <Hidden smDown>
              <Link to="/">
                <img src={SynergyLogo} alt="Synergy Logo" height="40" className={classes.menuLogo} />
              </Link>
            </Hidden>
            <Link to="/" className={classes.titleLink}>
              <Typography style={{ flexGrow: 1 }} color="inherit" variant="h6">Insight</Typography>
            </Link>

            <Hidden smDown>
              <Box style={{ margin: "auto" }} textAlign={'center'} mx={2}>
                <Typography>Welcome to Insight, an easy to search listing of Synergy's legislative and regulatory obligations.</Typography>
              </Box>
            </Hidden>

            <Hidden smDown>
              <Button className={classes.supportButton} size="medium" variant="outlined" onClick={this.handleSupportClicked}>
                Support
                <SupportIcon className={classes.supportButtonIcon} />
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton onClick={this.handleSupportClicked} className={classes.supportButton}>
                <SupportIcon />
              </IconButton>
            </Hidden>

            <IconButton color="inherit" onClick={this.openAuthMenu}>
              {user.photoURL
                ?
                <Avatar alt="Avatar" src={user.photoURL} />
                :
                <Avatar className={classes.avatar}>{userNameArray[0] + userNameArray[1]}</Avatar>}
            </IconButton>
            {user.isAdmin &&
              <Menu anchorEl={authMenu.anchorEl} open={Boolean(authMenu.anchorEl)} onClose={this.closeAuthMenu}>
                <MenuItem onClick={this.openAdmin}>Open admin</MenuItem>
              </Menu>
            }

          </Toolbar>
          <Box className={classes.barBottomLine} />
          <Box className={classes.filterBox} component="span">
            <TextField
              className={classes.textField}
              label="Type your search here"
              value={searchText}
              variant="filled"
              fullWidth
              size="small"
              onChange={event => this.handleChangeSearchText(event.target.value)}
              onKeyDown={this.handleSearchKeyPress}
              InputProps={{
                className: classes.textInput,
                endAdornment:
                  <IconButton onClick={this.handleClearSearch} className={classes.clearButton} size={"small"}>
                    {searchText === "" ?
                      <SearchIcon />
                      :
                      <ClearIcon />
                    }
                  </IconButton>
              }}
            />
          </Box>
          <Box className={classes.barBottomLine} />
        </AppBar>
      </React.Fragment >
    );
  }
}

export default withRouter(withStyles(styles)(Bar));
