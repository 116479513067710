// pass whole search string from withRouter.props.location
export function getUrlParamObject(fullSearchString) {
  if (fullSearchString === "") {
    return {};
  }

  try {
    const searchString = fullSearchString.split('?')[1];
    let paramObject = {};
    // split before decoding because user may put comma in search
    searchString.split(',').forEach(param => {
      const splitParam = param.split('=');
      paramObject[decodeURI(splitParam[0])] = decodeURI(splitParam[1]);
    });

    return paramObject;
  } catch (e) {
    console.error(e);
    return {};
  }
}

export function getUrlParamNameAndValue(urlParamObject) {
  const obligations = urlParamObject['Obligation category'];
  const regulators = urlParamObject['Relevant regulators'];
  const legislation = urlParamObject['Act'];
  const businessUnit = urlParamObject['Relevant business unit'];

  let paramObject = null;
  if (obligations) {
    paramObject = { id: "Obligation category", value: obligations, name: "Obligations" };
  } else if (regulators) {
    paramObject = { id: "Relevant regulators", value: regulators, name: "Relevant Regulators" };
  } else if (legislation) {
    paramObject = { id: "Act", value: legislation, name: "Legislation" };
  } else if (businessUnit) {
    paramObject = { id: "Relevant business unit", value: businessUnit, name: "Business Unit" };
  }

  return paramObject;

}